
import { useSnapshotStore } from "@/stores/snapshots"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"

export default {
  name: "Live",
  async asyncData({ redirect, app, route }) {
    const snapshotStore = useSnapshotStore()
    const cameraStore = useCameraStore()
    const routeCameraExid = camelizeKeys(route.params)?.cameraExid
    if (!useAccountStore().token && !cameraStore.selectedCamera) {
      return redirect("/v2/users/signin")
    }

    if (!app.$permissions.user.can.access.recordings()) {
      return redirect(cameraStore.cameraRoute)
    }

    if (
      !snapshotStore.cameraExid ||
      snapshotStore.cameraExid !== routeCameraExid ||
      cameraStore.isCameraOnline
    ) {
      await snapshotStore.updateLatestAndOldestSnapshots(routeCameraExid)
    }

    const timestamp = app
      .$moment(snapshotStore.latestSnapshotTimestamp)
      .tz(cameraStore.selectedCameraTimezone)
      .startOf("hour")
      .format("YYYY-MM-DDTHH:mm:ssZ")

    redirect(`${cameraStore.cameraRoute}/recordings/snapshots/${timestamp}`)
  },
}
